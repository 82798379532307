import App from "../../src/application/app";
import { useUserContext } from "../../src/application/context/user-context-provider";
import { addApolloState } from "../../src/application/graphql/init-apollo-client";
import CabinetLayout from "../../src/application/layouts/cabinet-layout";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import React from "react";
import type { AppPage } from "../_app";

const EventsPage = dynamic(
  () => import("../../src/features/events/pages/events-page")
);
const SearchEventsPage = dynamic(
  () => import("../../src/features/events/pages/search-events-page")
);

const CabinetAppPage: AppPage = () => {
  const {
    userContext: { user },
  } = useUserContext();
  // return (
  //   <div
  //     style={{
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center",
  //       fontSize: "2rem",
  //       height: "100vh",
  //     }}
  //   >
  //     Under construction
  //   </div>
  // );

  // if (user?.type === "SPOT") {
  //   return <EventsPage />;
  // }

  return <SearchEventsPage />;
};

CabinetAppPage.getLayout = (page: React.ReactElement) => {
  return <CabinetLayout title="Мероприятия">{page}</CabinetLayout>;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const app = await App.init({ headers: context.req.headers });

  // if (!app.isAuth()) {
  //   return {
  //     props: {},
  //     redirect: {
  //       destination: "/signin",
  //       permanent: false,
  //     },
  //   };
  // }

  // if (!app.isRegistered()) {
  //   return {
  //     props: {},
  //     redirect: {
  //       destination: "/registration",
  //       permanent: false,
  //     },
  //   };
  // }

  return addApolloState(app.apolloClient, {
    props: {},
  });
};

export default CabinetAppPage;
